import React from "react";

import { Box, Container } from "@material-ui/core";
import { Link } from "@runly/gatsby-theme";

import CtaSection from "../../cta";
import CtaButton from "../../cta/button";
import SectionHead from "../section-head";
import Feature from "../../feature-row";

import AppExample from "./examples/app";
import DataListExample from "./examples/data-list";
import RunExample from "../../../../content/docs/net/cli.svg";

import Languages from "../../languages";

const HowItWorks = props => (
	<CtaSection>
		<Box clone {...props}>
			<Container maxWidth="lg">
				<SectionHead title="How it works" mb={8}>
					If you can write a for-each loop, you can write a Runly job.
				</SectionHead>

				<Feature title="Create your job app" art={<AppExample />} sm={5} md={5}>
					Use a{" "}
					<FeatureLink href="https://github.com/runlyio/?q=template">
						repository template
					</FeatureLink>{" "}
					for your language to easily bootstrap a console/CLI project. Or if you
					are not into templates, just add a reference in your app to one of the
					core Runly packages:
					<Languages
						component="div"
						maxHeight={60}
						dotnet="/docs/net/"
						nodejs="/docs/nodejs/"
					/>
					Then let the Runly job host parse the CLI arguments and run your job.
				</Feature>

				<Feature
					title="Build your jobs"
					art={<DataListExample />}
					sm={5}
					md={5}
					reverse
				>
					Runly abstracts a common interface for jobs so that you can think of
					your job as a for-each loop, where a block of code executes for each
					item in a list. This simple construct naturally fits many problems
					that developers face everyday such as:
					<ul>
						<li>processing results from API calls</li>
						<li>processing results from database queries</li>
						<li>processing files on disk</li>
					</ul>
				</Feature>

				<Feature
					title="Run your jobs"
					art={<img src={RunExample} alt="Running a job" />}
					sm={5}
					md={5}
				>
					Building and running jobs in a predictable way allows us to build-in
					goodies on top of your core jobs. Things like an automated,
					parameterized CLI, multi-threading, scaling work across different
					machines, and resiliency and fault-tolerance become almost trivial for
					you to implement.
				</Feature>

				<Box textAlign="center" mt={12}>
					<CtaButton href="/docs/">Read the docs</CtaButton>
				</Box>
			</Container>
		</Box>
	</CtaSection>
);

const FeatureLink = props => (
	<Link color="inherit" underline="always" {...props} />
);

export default HowItWorks;
