// https://github.com/vmarchesin/react-konami-code/blob/master/src/Konami.jsx
// render null instead of display:none div

import React from "react";

class Konami extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			done: false,
			input: []
		};

		this.timeoutFunc = null;
	}

	componentDidMount() {
		const { resetDelay } = this.props;

		document.addEventListener("keyup", this.onKeyUp);
		const delay = Number(resetDelay);
		if (delay !== 0) {
			this._timer = new this.Timer(() => this.resetInput(), delay);
		}
	}

	componentWillUnmount() {
		const { resetDelay } = this.props;

		clearTimeout(this.timeoutFunc);
		if (resetDelay !== 0) {
			this._timer.stop();
		}
		document.removeEventListener("keyup", this.onKeyUp);
	}

	onKeyUp = e => {
		const { done, input } = this.state;
		const {
			action,
			code,
			disabled,
			onTimeout,
			resetDelay,
			timeout
		} = this.props;

		const delay = Number(resetDelay);

		if (disabled) {
			return;
		}

		if (delay !== 0) {
			this._timer.reset(delay);
		}

		input.push(e.keyCode);
		input.splice(-code.length - 1, input.length - code.length);

		this.setState({ input }, () => {
      if (this.state.input.join('').includes(code.join('')) && !done) { /* eslint-disable-line */
				if (delay !== 0) {
					this._timer.stop();
				}
				this.setState({ done: true }, () => {
					if (typeof action === "function") {
						action();
					}
				});

				if (timeout) {
					this.timeoutFunc = setTimeout(() => {
						this.setState({ done: false });
						if (typeof onTimeout === "function") {
							onTimeout();
						}
					}, Number(timeout));
				}
			}
		});
	};

	resetInput = () => this.setState({ input: [] });

	Timer(fn, t) {
		this.t = t;
		let timerObj = setInterval(fn, this.t);

		this.stop = function stop() {
			if (timerObj) {
				clearInterval(timerObj);
				timerObj = null;
			}
			return this;
		};

		this.start = function start() {
			if (!timerObj) {
				this.stop();
				timerObj = setInterval(fn, this.t);
			}
			return this;
		};

		this.reset = function reset(newT) {
			this.t = newT;
			return this.stop().start();
		};
	}

	render = () => {
		const { children, disabled } = this.props;
		const { done } = this.state;

		if (!done || disabled) {
			return null;
		}

		return children;
	};
}

Konami.defaultProps = {
	action: null,
	code: [38, 38, 40, 40, 37, 39, 37, 39, 66, 65],
	disabled: false,
	resetDelay: 1000,
	timeout: null
};

export default Konami;
