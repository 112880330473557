import React from "react";
import { CodeSample, MdxLayout } from "@runly/gatsby-theme";

import Javascript from "./javascript.mdx";
import Csharp from "./csharp.mdx";

const AppExample = () => (
	<MdxLayout>
		<CodeSample langs={{ csharp: <Csharp />, javascript: <Javascript /> }} />
	</MdxLayout>
);

export default AppExample;
