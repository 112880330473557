import React from "react";
import { upperFirst } from "lodash";
import tc from "tinycolor2";
import _data from "./data";

import { makeStyles } from "@material-ui/core/styles";
import { RoundedCard, ColorHeader, Content } from "../../../../hero/cards";
import { Grid } from "@material-ui/core";

import { ReactComponent as ApiIcon } from "./code-in-a-box.svg";
import { ReactComponent as DatabaseIcon } from "./database.svg";
import { ReactComponent as CsvFileIcon } from "./csv-file.svg";

const DataListExample = ({ data = _data }) => {
	const classes = useStyles();
	return (
		<>
			<Grid container spacing={3}>
				<Grid item xs={12} md={6}>
					<RoundedCard>
						<ColorHeader
							title={
								<>
									<ApiIcon /> API
								</>
							}
						/>
						<Content>
							<code className={classes.apiJsonView}>
								{JSON.stringify(data, null, 2)}
							</code>
						</Content>
					</RoundedCard>
				</Grid>
				<Grid item xs={12} md={6}>
					<RoundedCard>
						<ColorHeader
							title={
								<>
									<DatabaseIcon /> Database
								</>
							}
						/>
						<Content>
							<table className={classes.table}>
								<thead>
									<tr>
										{Object.keys(data.items[0]).map(k => (
											<th key={k}>{upperFirst(k)}</th>
										))}
									</tr>
								</thead>
								<tbody>
									{data.items.map(item => (
										<tr key={item.id}>
											{Object.values(item).map(val => (
												<td key={val}>{`${val}`}</td>
											))}
										</tr>
									))}
								</tbody>
							</table>
						</Content>
					</RoundedCard>
					<RoundedCard>
						<ColorHeader
							title={
								<>
									<CsvFileIcon /> File
								</>
							}
						/>
						<Content>
							<div className={classes.fileView}>
								{data.items.map(item => (
									<div key={item.id}>
										{Object.values(item)
											.map(v => (typeof v === "string" ? `"${v}"` : v))
											.join(",")}
									</div>
								))}
							</div>
						</Content>
					</RoundedCard>
				</Grid>
			</Grid>
		</>
	);
};

const useStyles = makeStyles(theme => {
	const { fontSize, fontWeight } = theme.typography.caption;
	return {
		apiJsonView: {
			display: "block",
			whiteSpace: "pre",
			textAlign: "left",
			fontSize,
			fontWeight
		},
		fileView: { textAlign: "left", fontSize, fontWeight },
		table: {
			width: "100%",
			borderSpacing: 0,
			borderCollapse: "collapse",
			"& th, & td": {
				padding: "0.25em 0.5em",
				border: `1px solid ${tc(theme.palette.primary.main)
					.setAlpha(0.12)
					.toString()}`,
				textAlign: "left"
			},
			fontSize,
			fontWeight
		}
	};
});
export default DataListExample;
