import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import HomeCode from "../code";

const CliExample = () => {
	const classes = useStyles();

	return (
		<HomeCode>
			<span>{">"} myapp helloworld --names Rick --names Morty</span>
			<br />
			<br />
			<span className={classes.headerKey}>{"\u00a0\u00a0"}log: </span>
			<span className={classes.keyOrUrl}>Hello, Rick</span>
			<br />
			<span className={classes.headerKey}>{"\u00a0\u00a0"}log: </span>
			<span className={classes.keyOrUrl}>Hello, Morty</span>
			<br />
			<br />
			<span>{"\u00a0\u00a0"}Job </span>
			<span className={classes.value}>Successful </span>
			<span>with 2 items</span>
		</HomeCode>
	);
};

const useStyles = makeStyles(() => {
	return {
		keyOrUrl: {
			color: "#b2cc98"
		},
		headerKey: { color: "#569cd6" },
		value: { color: "#49b5a1" }
	};
});

export default CliExample;
