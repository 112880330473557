import React, { useReducer, useEffect } from "react";

import { Typography, LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { ProgressText } from "@runly/ui";

const reducer = (state, action) => {
	switch (action) {
		case "increment": {
			if (state.success === state.total) {
				const resetCount = (state.resetCount || 0) + 1;

				if (resetCount === 30) {
					return { total: state.total, success: 0, failed: 0 };
				} else {
					return { ...state, resetCount };
				}
			}

			const success = state.success + 1;
			return { ...state, success, isDone: success === state.total };
		}

		default:
			return state;
	}
};

const useFakeRunState = () => {
	const [state, dispatch] = useReducer(reducer, {
		total: 100,
		success: 0,
		failed: 0
	});

	useEffect(() => {
		const interval = setInterval(() => {
			dispatch("increment");
		}, 100);

		return () => {
			clearInterval(interval);
		};
	}, []);

	return state;
};

const FakeProgressBar = ({ className, ...props }) => {
	const run = useFakeRunState();
	const classes = useStyles();

	const success = (run.success / run.total) * 100.0;
	const failed = ((run.success + run.failed) / run.total) * 100.0;

	return (
		<div className={`${classes.root} ${className || ""}`} {...props}>
			<div className={classes.inner}>
				<Typography variant="h6" component="h3">
					{!run.isDone ? <>Running your job...</> : <>Job complete</>}
				</Typography>
				<LinearProgress
					variant="buffer"
					value={success}
					valueBuffer={failed}
					classes={useProgressStyles()}
				/>
				<ProgressText
					progress={run}
					component={Typography}
					variant="caption"
					color="textSecondary"
				/>
			</div>
		</div>
	);
};

const useStyles = makeStyles({
	root: {
		display: "grid",
		placeItems: "center"
	},
	inner: {
		width: "90%"
	}
});

const useProgressStyles = makeStyles(theme => ({
	root: {
		height: theme.spacing(2)
	},
	bar1Buffer: {
		backgroundColor: theme.palette.success.main
	},
	bar2Buffer: {
		backgroundColor: theme.palette.error.main
	}
}));

export default FakeProgressBar;
