import React, { cloneElement } from "react";

import { Box, Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Link } from "@runly/gatsby-theme";

import CtaSection from "../../cta";
import CtaText from "../../cta/text";
import SectionHead from "../section-head";

import AppScreenshot from "./app.png";
import FakeProgressBar from "./progress-bar";

const WorkSmarter = ({ children, ...props }) => (
	<CtaSection backgroundColor="paper">
		<Box clone {...props}>
			<Container maxWidth="lg">
				<SectionHead title="Go beyond background jobs" mb={16}>
					Your app's long-running work is killing your performance and driving
					your users away. <strong>Let's fix it.</strong>
				</SectionHead>

				<Grid container spacing={8}>
					<FeatureItem
						title="Background jobs without the hassle"
						art={
							<img
								src={AppScreenshot}
								alt="Runly App"
								style={{ display: "block" }}
							/>
						}
						cta="Learn more about the platform"
						to="/platform/"
					>
						Automate the deployment, monitoring, and scaling of your jobs with
						the{" "}
						<Link to="/platform/" color="inherit" underline="always">
							Runly Platform
						</Link>
						. Deploy your jobs as simple NuGet/npm packages to run on your
						hardware or your favorite cloud.
					</FeatureItem>

					<FeatureItem
						title={
							<>
								Bring <abbr title="User Experience">UX</abbr> to the foreground
							</>
						}
						art={<FakeProgressBar />}
						cta="Learn more about Runly UI"
						to="/ui/"
					>
						Make your app more responsive and less error-prone. Don't make your
						users wait for background work without any feedback. Notify them
						when work is finished by integrating progress bars and results into
						your app with{" "}
						<Link to="/ui/" color="inherit" underline="always">
							Runly UI
						</Link>
						.
					</FeatureItem>
				</Grid>

				{children}
			</Container>
		</Box>
	</CtaSection>
);

const FeatureItem = ({ title, art, children, cta, to }) => {
	const classes = useFeatureStyles();

	return (
		<Grid item xs={12} md={6}>
			{cloneElement(art, { className: classes.art })}

			<CtaText title={title} className={classes.text}>
				{children}
			</CtaText>

			<Box textAlign="center" my={4}>
				<Button to={to} variant="outlined" color="primary">
					{cta}
				</Button>
			</Box>
		</Grid>
	);
};

const useFeatureStyles = makeStyles(theme => ({
	art: {
		margin: `0 auto ${theme.spacing(4)}px auto`,
		[theme.breakpoints.up("md")]: {
			height: 350
		}
	},
	text: {
		[theme.breakpoints.up("md")]: {
			height: theme.spacing(20)
		}
	}
}));

export default WorkSmarter;
