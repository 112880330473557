import React from "react";
import { graphql } from "gatsby";

import Layout from "../layout";
import Seo from "../seo";

import CtaSection from "../cta";

import Hero from "../hero";
import CodeSamples from "../landing/code-samples";
import HowItWorks from "../landing/how-it-works";
import Beyond from "../landing/beyond";
import GetStarted from "../landing/get-started";

import Easter from "../easter";

const HomePage = ({ location, data: { logo } }) => (
	<Layout
		location={location}
		isLandingPage
		isOnHero
		disableFooterMargin
		maxWidth="lg"
	>
		<Seo
			title="Modern, full life cycle job platform"
			description="Runly is a .NET and Node.js based open source framework for creating multi-threaded background jobs. Offload long-running work out of your app's critical path."
			image={logo.cover.img.src}
		/>
		<Hero
			title={
				<>
					The modern way to <strong>build jobs</strong>
				</>
			}
		>
			Runly is a .NET and Node.js based open source framework for creating
			multi-threaded background jobs. Offload long-running work out of your
			app's critical path to provide a great user experience.
		</Hero>
		<CodeSamples py={20} />
		<HowItWorks py={20} />

		<Beyond py={20} />

		<CtaSection py={20}>
			<GetStarted mt={8} />
		</CtaSection>
		<Easter />
	</Layout>
);

export default HomePage;

export const homeQuery = graphql`
	query HomeQuery {
		logo: file(sourceInstanceName: { eq: "logos" }, name: { eq: "cover" }) {
			cover: childImageSharp {
				img: fixed(width: 800) {
					src
				}
			}
		}
	}
`;
