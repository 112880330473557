import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Konami from "./konami";

import { ReactComponent as Bus } from "./bus.svg";
import Horn from "./horn.mp3";

const EasterEgg = () => {
	const classes = useStyles();

	return (
		<Konami>
			<Bus className={classes.bus} />
			<audio src={Horn} autoPlay />
		</Konami>
	);
};

const useStyles = makeStyles({
	bus: {
		position: "fixed",
		width: "60%",
		left: "50%",
		top: "50%",
		transform: "translate(-50%, -50%)",
		animation: "$pulse 2.5s"
	},
	"@keyframes pulse": {
		"0%": {
			width: "10%"
		},
		"30%": {
			width: "80%"
		},
		"40%": {
			width: "60%"
		},
		"50%": {
			width: "80%"
		},
		"60%": {
			width: "60%"
		},
		"70%": {
			width: "80%"
		},
		"80%": {
			width: "60%"
		},
		"90%": {
			width: "80%"
		},
		"100%": {
			width: "60%"
		}
	}
});

export default EasterEgg;
